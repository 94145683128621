import React, { useContext } from 'react';
import { LayoutItem, Icon } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import { AudiHeaderDealerProps } from '../../interfaces/header-components.interfaces';
import {
  HeaderStyledDealerDisplay,
  HeaderStyledDealerTextWrapper,
  HeaderStyledDealerLink,
  HeaderStyledDealerLabel,
  HeaderStyledDealerName,
  HeaderStyledDealerButton,
} from './AudiHeaderDealerContextStyles';
import AudiHeaderAdditionalDealerData from './AudiHeaderAdditionalDealerData';
import { truncate } from '../../utils/string-utils';
import { messages } from '../../i18n/messages';
import { DealerContext } from '../../context';

const AudiHeaderDealerContext: React.FC<AudiHeaderDealerProps> = ({
  isMobileView = false,
  headerNavigationItemsAmount,
  onClickDealerContext,
  showDealerAdditionalData,
}) => {
  const dealerLabel = useI18n(messages.dealer_label);
  const showDealerInfo = useI18n(messages.dealer_info);
  const hideDealerInfo = useI18n(messages.hide_dealer_info);
  const dealerButtonLabel = showDealerAdditionalData ? hideDealerInfo : showDealerInfo;
  const arrowIcon = showDealerAdditionalData ? (
    <Icon name="arrow-up" size="small" variant="primary" />
  ) : (
    <Icon name="arrow-down" size="small" variant="primary" />
  );
  const dealerIcon = showDealerAdditionalData ? (
    <Icon name="cancel" size="small" variant="primary" />
  ) : (
    <Icon name="my-dealer" size="small" variant="primary" />
  );

  const { dealerData } = useContext(DealerContext);

  return (
    <HeaderStyledDealerDisplay
      isMobileView={isMobileView}
      headerNavigationItemsAmount={headerNavigationItemsAmount}
    >
      <HeaderStyledDealerButton
        isMobileView={isMobileView}
        title={dealerButtonLabel}
        aria-label={dealerButtonLabel}
        type="button"
        onClick={onClickDealerContext}
        data-testid="dealer-data-toggle"
      >
        {!isMobileView && (
          <LayoutItem align="center" spaceInlineEnd="s">
            {dealerIcon}
          </LayoutItem>
        )}
        <HeaderStyledDealerTextWrapper>
          <HeaderStyledDealerLink>
            <HeaderStyledDealerLabel isMobileView={isMobileView}>
              <span>{dealerLabel}</span>
            </HeaderStyledDealerLabel>
            <HeaderStyledDealerName isMobileView={isMobileView}>
              {!isMobileView
                ? truncate(dealerData?.additionalData?.displayName || dealerData?.name)
                : dealerData?.additionalData?.displayName || dealerData?.name || 'Name not found'}
            </HeaderStyledDealerName>
          </HeaderStyledDealerLink>
        </HeaderStyledDealerTextWrapper>
        {isMobileView && <LayoutItem align="center">{arrowIcon}</LayoutItem>}
      </HeaderStyledDealerButton>
      <AudiHeaderAdditionalDealerData open={showDealerAdditionalData} isMobileView={isMobileView} />
    </HeaderStyledDealerDisplay>
  );
};

export default AudiHeaderDealerContext;
