import React, { createContext, useRef, useMemo } from 'react';
import { IAuthServiceProviderV2, IAuthServiceV2 } from '@oneaudi/audi-auth-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { FeatureServiceDependencies } from '../FeatureHubAppDefinition';
import { Dealer } from '../interfaces/header-components.interfaces';

interface State {
  readonly authServiceProvider: IAuthServiceProviderV2;
  readonly localeService: LocaleServiceV1;
}

interface ContextValue {
  readonly featureServices: FeatureServiceDependencies;
  readonly children: JSX.Element;
}

export const AuthContext = React.createContext<IAuthServiceV2>({} as IAuthServiceV2);
AuthContext.displayName = 'auth-context';

export const Context = React.createContext<State>({} as State);

interface DealerContextInterface {
  readonly renderDealerContext: boolean;
  readonly dealerData?: Dealer;
  readonly removeDealer: () => void;
}

interface ITrackingService {
  trackingService: TrackingServiceV2;
}

export const DealerContext = createContext<DealerContextInterface>({} as DealerContextInterface);
export const TrackingServiceContext = createContext<ITrackingService>({} as ITrackingService);

export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const { 'vw:authService': authServiceProvider, 'locale-service': localeService } =
    featureServices;

  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = useMemo(
    () => ({
      authServiceProvider,
      localeService,
    }),
    [authServiceProvider, localeService],
  );

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};
