import '../../styles/bodyClasses.css';

import React, { Suspense, lazy, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from 'styled-components';
import {
  createResetActiveItemAction,
  createSetActiveItemAction,
} from '../../actions/header-actions';
import { DealerContext, TrackingServiceContext } from '../../context';
import { AudiHeaderProps } from '../../interfaces/header-components.interfaces';
import { useClickOnElementByClassName, useStateSwitchTimeOutHook } from '../../services/hooks';
import AudiHeaderDealerContext from '../AudiHeaderDealerContext/AudiHeaderDealerContext';
import AudiHeaderLogo from '../AudiHeaderLogo/AudiHeaderLogo';
import AudiHeaderMenuButton from '../AudiHeaderMenuButton/AudiHeaderMenuButton';
import AudiHeaderMiniCart from '../AudiHeaderMiniCart/AudiHeaderMiniCart';
import AudiHeaderNav from '../AudiHeaderNav/AudiHeaderNav';
import AudiSearchFeatureAppLoader from '../AudiHeaderSearch/AudiSearchFeatureAppLoader';
import AudiHeaderSearchButton from '../AudiHeaderSearchButton/AudiHeaderSearchButton';
import AudiUserMenu from '../AudiUserMenu/AudiUserMenu';
import AudiWishlist from '../AudiWishlist/AudiWishlist';
import { timeS } from './AudiHeaderStyleUtils';
import {
  HeaderStyled,
  HeaderStyledShader,
  HeaderStyledUserActionWrapper,
  HeaderStyledWrapper,
} from './AudiHeaderStyles';
import { mobileMenuClick, myAudiPopoverClick } from '../../utils/tracking';

const SearchFeatureApp = lazy(
  () => import(/* webpackChunkName: "lazy-search" */ '../AudiHeaderSearch/AudiSearchFeatureApp'),
);

// eslint-disable-next-line max-statements,@typescript-eslint/ban-ts-comment
// @ts-ignore
const AudiHeader: React.FC<AudiHeaderProps> = (props) => {
  const {
    authService,
    envConfigService,
    headerState,
    headerDispatch,
    audiMarketContextService,
    actionWrapperElement,
    headerConfig,
    headerStateService,
    isLoading,
    layerManager,
    externalFeatureApps,
    navigationElement,
    wrapperElement,
    featureAppId,
    useOneLayer,
    useFootnoteReferenceServiceTextParserHook,
    useUserMenu,
    eventService,
  } = props;
  const headerNavigationItemsAmount = useMemo(
    () => headerConfig?.MainNavigation?.length || 0,
    [headerConfig?.MainNavigation?.length],
  );

  const { dealerData, renderDealerContext } = useContext(DealerContext);
  const { trackingService } = useContext(TrackingServiceContext);

  const showDealerContext = !!dealerData && renderDealerContext;
  const showSearchButton =
    headerConfig?.Search &&
    externalFeatureApps &&
    externalFeatureApps.searchInputFeatureAppUrl &&
    externalFeatureApps.searchResultsFeatureAppUrl;
  const showMiniCart =
    !dealerData &&
    headerConfig?.OneShopEnabled &&
    externalFeatureApps &&
    externalFeatureApps.miniCartFeatureAppBaseUrl &&
    externalFeatureApps.miniCartFeatureAppSrc &&
    externalFeatureApps.oneShopUbffUrl;
  const showMyAudi =
    headerConfig?.Login && externalFeatureApps && externalFeatureApps.loginFeatureAppUrl;
  const actionItems = [showDealerContext, showSearchButton, showMiniCart, showMyAudi, showMyAudi];
  const totalActionItems = useMemo(() => actionItems.filter(Boolean).length, [actionItems]);

  const showShader = useMemo(
    () =>
      headerState.activeItem.index !== -1 ||
      headerState.activeItem.showSearch ||
      headerState.activeItem.showDealer,
    [headerState.activeItem],
  );

  const [showShaderDelayed, setShowShaderDelayed] = useState(showShader);

  useEffect(() => {
    setTimeout(
      () => {
        setShowShaderDelayed(showShader);
      },
      showShader ? 1 : parseInt(timeS, 10),
    );
  }, [showShader]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disableTransition, setDisableTransition] = useState<boolean>(false);

  const { state: isClosing, stateSwitch: addClosingAnimation } = useStateSwitchTimeOutHook(440);

  const [windowWidth, setWindowWith] = useState(
    (typeof window !== 'undefined' && window.innerWidth) || 0,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const themeContext = useContext(ThemeContext);

  // Learn more link for User Menu
  const marketUrl = headerConfig?.Login?.MarketUrl;

  // optional "Overview" page on CTA "Login"
  const overviewUrl = headerConfig?.Login?.OverviewUrl;

  // myAudi Account link
  const userAccountUrl = `${headerConfig?.Login?.MyAudiAccountUrl}`;

  // myAudi Wishlist link
  const wishlistUrl = `${headerConfig?.Login?.WishlistUrl}`;

  const redirectUrl = `${headerConfig?.Login?.RedirectUrl}`;

  useEffect(() => {
    let isMounted = true;
    const resizeHandler = (): void => {
      setWindowWith((typeof window !== 'undefined' && window.innerWidth) || 0);

      let timer: ReturnType<typeof setTimeout> | null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        setDisableTransition(true);
      }

      timer = setTimeout(() => {
        if (isMounted) {
          setDisableTransition(false);
        }
        timer = null;
      }, 200);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler);
    }
    return (): void => {
      isMounted = false;
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler);
      }
    };
  }, []);

  const closeMenu: () => void = () => {
    const layers = layerManager?.getLayers() || [];
    if (layers.length > 0) {
      return;
    }

    headerDispatch(createResetActiveItemAction(headerStateService));
    addClosingAnimation();

    setIsOpen(false);
  };

  useClickOnElementByClassName({
    className: 'audi-j-footnote-reference',
    clickCallBack: closeMenu,
  });

  useEffect(() => {
    const expandedClass = 'u-header-expanded';
    if (isOpen || headerState.activeItem.showSearch) {
      document.body.classList.add(expandedClass);
    } else {
      document.body.classList.remove(expandedClass);
    }
  }, [isOpen, headerState.activeItem.showSearch]);

  useEffect(() => {
    let isMounted = true;

    if (isLoading) {
      setDisableTransition(true);
    } else {
      setTimeout(() => {
        if (isMounted) {
          setDisableTransition(false);
        }
      }, 660);
    }

    return (): void => {
      isMounted = false;
    };
  }, [isLoading]);

  useEffect(() => {
    headerStateService.registerCallback((headerStateRemote) => {
      if (headerStateRemote.showLoginFlyout === true) {
        headerDispatch(
          createSetActiveItemAction({
            anchor: null,
            headerStateService,
            index: -1,
            showSearch: false,
            showDealer: false,
            showLoginFlyout: false,
          }),
        );
        setIsOpen(false);
      }
    });
  }, [headerStateService, headerDispatch]);

  const onClickMenuButton: () => void = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
      headerDispatch(createResetActiveItemAction(headerStateService));
      headerStateService.setShowLoginFlyout(false);
      trackingService?.track(mobileMenuClick('open', headerState.activeItem.index, 'text link'));
    } else {
      closeMenu();
      trackingService?.track(mobileMenuClick('close', headerState.activeItem.index, 'icon'));
    }
  };

  const handleClickUserMenu: () => void = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
      headerDispatch(
        createSetActiveItemAction({
          anchor: null,
          headerStateService,
          index: -1,
          showSearch: false,
          showDealer: false,
          showLoginFlyout: true,
        }),
      );
      trackingService?.track(
        myAudiPopoverClick('open myaudi popover', headerNavigationItemsAmount + totalActionItems),
      );
    } else {
      headerDispatch(createResetActiveItemAction(headerStateService));
      trackingService?.track(
        myAudiPopoverClick('close myaudi popover', headerNavigationItemsAmount + totalActionItems),
      );
    }
  };

  const handleClickWishlist: () => void = () => {
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      }),
    );
  };

  const onClickDealerContext: () => void = () => {
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: false,
        showDealer: !headerState.activeItem.showDealer,
        showLoginFlyout: false,
      }),
    );
  };

  const isSmallView =
    (windowWidth <= themeContext?.breakpoints?.xxl && headerNavigationItemsAmount >= 8) ||
    (windowWidth <= themeContext?.breakpoints?.xl && headerNavigationItemsAmount >= 5) ||
    (windowWidth <= themeContext?.breakpoints?.l && headerNavigationItemsAmount >= 4);
  const legacyClassNames = isSmallView
    ? 'header header--loaded header--view-small'
    : 'header header--loaded';

  useEffect(() => {
    headerStateService.setisMobileView(isSmallView);
  }, [isSmallView, headerStateService]);

  if (!themeContext) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return (
    <>
      <HeaderStyled
        className={legacyClassNames}
        data-fefa-custom-id={featureAppId}
        data-module="one-header"
        data-navigation-items-amount={headerNavigationItemsAmount}
        disableTransition={disableTransition}
        ref={wrapperElement}
      >
        {showShader
          ? showShader && <HeaderStyledShader onClick={closeMenu} showShader={showShaderDelayed} />
          : showShaderDelayed && <HeaderStyledShader onClick={closeMenu} showShader={showShader} />}
        <HeaderStyledWrapper headerNavigationItemsAmount={headerNavigationItemsAmount}>
          <AudiHeaderLogo
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isLoading={isLoading}
            isOpen={isOpen}
            logoLink={headerConfig?.Logo}
          />
          <AudiHeaderMenuButton
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isLoading={isLoading}
            isOpen={isOpen}
            label={headerConfig?.MenuLabel}
            onClick={onClickMenuButton}
          />
          <>
            <AudiHeaderNav
              audiMarketContextService={audiMarketContextService}
              headerDispatch={headerDispatch}
              headerNavigationItemsAmount={headerNavigationItemsAmount}
              headerState={headerState}
              headerStateService={headerStateService}
              isClosing={isClosing}
              isOpen={isOpen}
              layerManager={layerManager}
              navigation={headerConfig?.MainNavigation}
              navigationElement={navigationElement}
              useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
              useOneLayer={useOneLayer}
              useUserMenu={useUserMenu}
              windowWidth={windowWidth}
              onClickDealerContext={onClickDealerContext}
              showDealerAdditionalData={headerState.activeItem.showDealer}
            />
            <HeaderStyledUserActionWrapper isLoading={isLoading} ref={actionWrapperElement}>
              {showDealerContext && (
                <AudiHeaderDealerContext
                  headerNavigationItemsAmount={headerNavigationItemsAmount}
                  onClickDealerContext={onClickDealerContext}
                  showDealerAdditionalData={headerState.activeItem.showDealer}
                />
              )}
              {showSearchButton && (
                <AudiHeaderSearchButton
                  headerDispatch={headerDispatch}
                  headerNavigationItemsAmount={headerNavigationItemsAmount}
                  headerStateService={headerStateService}
                  isOpen={isOpen}
                  search={headerConfig.Search}
                />
              )}
              {showMiniCart && (
                <AudiHeaderMiniCart
                  headerNavigationItemsAmount={headerNavigationItemsAmount}
                  isOpen={isOpen}
                  miniCartFeatureAppBaseUrl={externalFeatureApps.miniCartFeatureAppBaseUrl}
                  miniCartFeatureAppSrc={externalFeatureApps.miniCartFeatureAppSrc}
                  oneShopUbffUrl={externalFeatureApps.oneShopUbffUrl}
                />
              )}
              {showMyAudi && (
                <>
                  <AudiWishlist
                    authService={authService}
                    headerState={headerState}
                    headerNavigationItemsAmount={headerNavigationItemsAmount}
                    isOpen={isOpen}
                    useUserMenu={useUserMenu}
                    wishlistUrl={wishlistUrl}
                    userAccountUrl={userAccountUrl}
                    onClickWishlist={handleClickWishlist}
                  />
                  <AudiUserMenu
                    authService={authService}
                    headerState={headerState}
                    headerNavigationItemsAmount={headerNavigationItemsAmount}
                    isOpen={isOpen}
                    onClickUserMenu={handleClickUserMenu}
                    myAudiUrl={headerConfig?.Login?.Url}
                    overviewUrl={overviewUrl}
                    marketBaseUrl={marketUrl}
                    userAccountUrl={userAccountUrl}
                    envConfigService={envConfigService}
                    loginFeatureAppUrl={externalFeatureApps.loginFeatureAppUrl}
                    audiMarketContextService={audiMarketContextService}
                    useUserMenu={useUserMenu}
                    redirectUrl={redirectUrl}
                    eventService={eventService}
                    supplier={headerConfig?.Suppliers}
                    totalActionItems={totalActionItems}
                  />
                </>
              )}
            </HeaderStyledUserActionWrapper>
            {headerState.activeItem.showSearch &&
              headerConfig?.Search &&
              externalFeatureApps &&
              externalFeatureApps.searchInputFeatureAppUrl &&
              externalFeatureApps.searchResultsFeatureAppUrl && (
                <Suspense fallback={<AudiSearchFeatureAppLoader />}>
                  <SearchFeatureApp
                    dealerSearchLink={headerConfig.Search.DealerSearchLink}
                    oneHeaderSearchClientId={headerConfig.Search.OneHeaderSearchClientId}
                    vtpConfig={{
                      dealerSearch: !!headerConfig.Search.ShowDealerSearchLink,
                      newStockCarSearch: !!headerConfig.Search.ShowNewCarsLink,
                      usedAndPreOwnedStockCarSearch: !!headerConfig.Search.ShowUsedCarsLink,
                    }}
                    mainNavigation={headerConfig.MainNavigation}
                    headerConfig={headerConfig}
                    headerDispatch={headerDispatch}
                    legend={headerConfig.Search.Legend}
                    searchInputFeatureAppUrl={externalFeatureApps.searchInputFeatureAppUrl}
                    searchResultsFeatureAppUrl={externalFeatureApps.searchResultsFeatureAppUrl}
                  />
                </Suspense>
              )}
          </>
        </HeaderStyledWrapper>
      </HeaderStyled>
      {/* remove after AEM template adaption (extract audi-header from nm-wrapper) */}
      <div className="u-header-nemo-fullwidth-patch" />
    </>
  );
};

export default AudiHeader;
