import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { responsiveStyles } from '@audi/audi-ui-react';
import {
  HeaderStyledDealerButtonProps,
  HeaderStyledDealerDisplayProps,
} from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services';
import { AudiCopy1WithHoverStyle } from '../AudiHeader/AudiHeaderStyleUtils';
import {
  headerStyledDealerContextMobileStyles,
  headerStyledDealerContextStyles,
} from './DealerContextResponsiveStyles';

export const HeaderStyledDealerDisplay = styled.div<HeaderStyledDealerDisplayProps>`
  display: none;
  ${({ headerNavigationItemsAmount, theme, isMobileView }): string => {
    return isMobileView
      ? responsiveStyles(
          createBreakpointStyles(
            headerNavigationItemsAmount,
            headerStyledDealerContextMobileStyles(),
          ),
          theme.breakpoints,
        )
      : responsiveStyles(
          createBreakpointStyles(headerNavigationItemsAmount, headerStyledDealerContextStyles()),
          theme.breakpoints,
        );
  }};
  & ::-webkit-scrollbar {
    display: none; /* hide scrollbar for Chrome, Safari, and Opera */
    width: 0;
  }
  -ms-overflow-style: none; /*  hide scrollbar for Internet Explorer, Edge */
`;

export const HeaderStyledDealerButton = styled.button<HeaderStyledDealerButtonProps>`
  cursor: pointer;
  outline: 0 none;
  border: 0 none;
  padding: var(--one-header-space-s) var(--one-header-space-xl) var(--one-header-space-s) 0;
  margin: 0;
  background: 0 none;
  width: 100%;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &:focus-visible {
    border: 2px solid;
    border-radius: 3px;
  }
  ${({ isMobileView }) =>
    isMobileView &&
    css`
      margin-top: 20px;
      padding: calc(var(--one-header-space-xxl) - var(--one-header-space-l) / 2)
        var(--one-header-side-spacing);
      border-top: 1px solid var(${({ theme }): string => theme.colors.base.grey[20]});
      border-bottom: 1px solid var(${({ theme }): string => theme.colors.base.grey[20]});
    `};
`;

export const HeaderStyledDealerTextWrapper = styled.div`
  color: var(${({ theme }): string => theme.colors.base.grey[60]});
  text-align: left;
  flex-grow: 1;

  &:hover,
  &:active,
  &:focus {
    color: var(--one-header-color-black);
  }

  &:hover {
    color: var(--one-header-color-black);
  }

  &:active {
    color: var(--one-header-color-black);
  }
`;

export const HeaderStyledDealerLink = styled.a`
  color: var(${({ theme }): string => theme.colors.base.grey[60]});
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--one-header-color-black);
  }

  &:hover {
    color: var(--one-header-color-black);
  }

  &:active {
    color: var(--one-header-color-black);
  }
`;

interface HeaderStyleDealyerLabelAndNameProps {
  isMobileView: boolean;
}

const getFontStyle = (style: string, bold?: boolean) => css`
  font-size: var(${({ theme }): string => theme.responsive.typography[style].fontSize});
  line-height: var(${({ theme }): string => theme.responsive.typography[style].lineHeight});
  font-weight: ${bold ? 'bold' : 'normal'};
`;

export const HeaderStyledDealerLabel = styled.label<HeaderStyleDealyerLabelAndNameProps>`
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}
  ${({ isMobileView }) => (isMobileView ? getFontStyle('copy2', true) : getFontStyle('copy3'))};
  display: block;
`;

export const HeaderStyledDealerName = styled.span<HeaderStyleDealyerLabelAndNameProps>`
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}
  ${({ isMobileView }) => (isMobileView ? getFontStyle('copy1') : getFontStyle('copy2'))};
`;
