export const messages = {
  open_until: {
    defaultMessage: 'Open until',
    id: 'nemo.ui.dealer-context.opening-hours.open-text-fragment',
  },
  closed_fragment: {
    defaultMessage: 'Closed, opens',
    id: 'nemo.ui.dealer-context.opening-hours.closed-text-fragment',
  },
  today: {
    defaultMessage: 'Today at',
    id: 'nemo.ui.dealer-context.opening-hours.today-text-fragment',
  },
  time_format: {
    defaultMessage: 'h',
    id: 'nemo.ui.dealer-context.opening-hours.time-format',
  },
  dealer_label: {
    defaultMessage: 'Connected to:',
    id: 'nemo.ui.dealer-context.label',
  },
  dealer_info: {
    defaultMessage: 'show additional dealer info',
    id: 'nemo.ui.dealer-context.show-additional-dealer-data',
  },
  hide_dealer_info: {
    defaultMessage: 'hide additional dealer info',
    id: 'nemo.ui.dealer-context.hide-additional-dealer-data',
  },
  back_to_dealer: {
    defaultMessage: 'Back to dealer',
    id: 'nemo.ui.dealer-context.back-button-text',
  },
  remove_dealer: {
    defaultMessage: 'Disconnect',
    id: 'nemo.ui.dealer-context.remove-dealer-label',
  },
  remove_dealer_help: {
    defaultMessage:
      'By forgetting this dealer, your dealer connection will be removed from the header. To visit this or another dealership, visit the store locator.',
    id: 'nemo.ui.dealer-context.remove-dealer-help-text',
  },
  sales: {
    defaultMessage: 'Sales',
    id: 'nemo.ui.dealer-context.sales-text',
  },
  sales_r8: {
    defaultMessage: 'Sales R8',
    id: 'nemo.ui.dealer-context.sales-r8-text',
  },
  service_r8: {
    defaultMessage: 'Service',
    id: 'nemo.ui.dealer-context.service-r8-text',
  },
  service: {
    defaultMessage: 'Service',
    id: 'nemo.ui.dealer-context.service-text',
  },
  service_no_agent: {
    defaultMessage: 'Service No Agent',
    id: 'nemo.ui.dealer-context.service-noagent-text',
  },
  etron: {
    defaultMessage: 'Etron',
    id: 'nemo.ui.dealer-context.etron-text',
  },
  usedcars: {
    defaultMessage: 'Used Cars',
    id: 'nemo.ui.dealer-context.usedcars-text',
  },
  aftersales: {
    defaultMessage: 'After Sales',
    id: 'nemo.ui.dealer-context.aftersales-text',
  },
  ovt: {
    defaultMessage: 'Ovt',
    id: 'nemo.ui.dealer-context.ovt-text',
  },
  audi_on_demand: {
    defaultMessage: 'Audi on demand',
    id: 'nemo.ui.dealer-context.audi-on-demand-text',
  },
};
