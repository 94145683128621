import styled from 'styled-components';

export const DealerDetailStyled = styled.div`
  display: flex;
  margin: 0 0 var(--one-header-space-l);
`;

export const OpenHoursStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const DealerTelStyled = styled.div`
  cursor: pointer;
`;
