export const matchCountryUrlOrigin = (href: string | URL, urlPattern: string | URL) => {
  try {
    // Create URL objects to extract origins
    const hrefUrl = new URL(href);
    const patternUrl = new URL(urlPattern);

    // Compare origins (protocol + hostname + port)
    return hrefUrl.origin === patternUrl.origin;
  } catch (error) {
    // Handle invalid URLs
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line no-console
    console.warn('Invalid Country domain URL provided:', error.message);
    return false;
  }
};
