import { Loader } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  background-color: var(--one-header-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  margin: 12px 0;
  z-index: 5;
`;

const AudiSearchFeatureAppLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

export default AudiSearchFeatureAppLoader;
