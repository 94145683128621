/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import AudiHeaderDataManager from './components/AudiHeader/AudiHeaderDataManager';
import type { AudiHeaderDataManagerProps } from './interfaces/header-components.interfaces';

const FeatureApp: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  config,
  enablePartnerIdReplacement,
  featureAppId,
  featureServices,
  headerConfigDataUrl,
  headerStateService,
  layerManager,
  referenceServiceManager,
  ssrHeaderData,
  authService,
  localeService,
  envConfigService,
  dealerContextService,
  contentHeadless,
  eventService,
}) => {
  return (
    <AudiHeaderDataManager
      audiMarketContextService={audiMarketContextService}
      config={config}
      enablePartnerIdReplacement={enablePartnerIdReplacement}
      featureAppId={featureAppId}
      featureServices={featureServices}
      headerConfigDataUrl={headerConfigDataUrl}
      headerStateService={headerStateService}
      layerManager={layerManager}
      referenceServiceManager={referenceServiceManager}
      ssrHeaderData={ssrHeaderData}
      authService={authService}
      localeService={localeService}
      envConfigService={envConfigService}
      dealerContextService={dealerContextService}
      contentHeadless={contentHeadless}
      eventService={eventService}
    />
  );
};

export default FeatureApp;
