/*
**NOTE**
**This a temporary solution and should be remove with audi-partner-utils package when fixed**
Current package "@volkswagen-onehub/audi-partner-utils": "3.0.0" throws an error when installed. 
This file transformServices.ts was create to fulfill functionality of https://github.com/volkswagen-onehub/audi-partner-utils/blob/main/src/partner-service-string/partner-service-string.ts
*/

export const transformServices = (
  partnerServices: string[],
  partnerServiceLabels: Map<string, string>,
  partnerAdditionalServices?: string[] | undefined,
): string => {
  const relevantServices: string[] = [...partnerServiceLabels.keys()];

  let services = [
    ...partnerServices
      .filter((value: string) => relevantServices.includes(value))
      .map((service: string) => partnerServiceLabels.get(service)),
  ].join(', ');

  if (partnerAdditionalServices && partnerAdditionalServices?.length > 0) {
    const additionalServices = [...partnerAdditionalServices].join(', ');

    services = [services, additionalServices].join(', ');
  }

  // remove any whitespace in services string
  services = services.replace(/,\s*$/, '');
  return services;
};
