import React from 'react';
import { HeaderActionTypes, type IHeaderAction } from '../actions/header-actions';

export interface ActiveItem {
  anchor: HTMLAnchorElement | null;
  index: number;
  showSearch: boolean;
  showDealer: boolean;
  showLoginFlyout: boolean;
}

export interface HeaderState {
  activeItem: ActiveItem;
}

export const headerReducer: React.Reducer<HeaderState, IHeaderAction> = (state, action) => {
  switch (action.type) {
    case HeaderActionTypes.SetActiveItem:
      return { ...state, ...action.payload };
    case HeaderActionTypes.LoadSearchFA:
      return { ...state };
    default:
      return state;
  }
};
