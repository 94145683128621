export const wishlist = {
  wishlistHeadline: {
    defaultMessage: 'Your wish list',
    id: 'fa.header.wishlist.headline',
  },
  wishlistSubHeadline: {
    defaultMessage:
      'Get an overview of your saved vehicles. A myAudi account is required for using the wish list.',
    id: 'fa.header.wishlist.subheadline',
  },
  wishlistLogin: {
    defaultMessage: 'Login',
    id: 'fa.header.wishlist.login',
  },
  wishlistIconAriaLabel: {
    defaultMessage: 'Open Wishlist',
    id: 'fa.header.wishlist.icon.aria.label',
  },
};
