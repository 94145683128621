import {
  AudiFootnoteReferenceServiceInterfaceV3,
  EnumerableFootnote,
} from '@oneaudi/footnote-reference-service';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAuthServiceV2 } from '@oneaudi/audi-auth-service';
import { loopOverReferences, replaceTextWithFootnoteNumber, setDataSetOnElement } from './index';
import { scriptRegex } from '../utils/string-utils';

export const timoutFunctionCaller = (timeInMs: number, callback: () => void): void => {
  setTimeout(() => {
    callback();
  }, timeInMs);
};

export function useStateSwitchTimeOutHook(
  timeInMs: number,
  initialState = false,
): { state: boolean; stateSwitch: () => void } {
  const [state, setState] = useState(initialState);
  const stateSwitch: () => void = () => {
    setState(!state);
    timoutFunctionCaller(timeInMs, () => {
      setState(!state);
    });
  };
  return { state, stateSwitch };
}

export function useClickOnElementByClassName({
  className,
  clickCallBack,
}: {
  className: string;
  clickCallBack: () => void;
}): boolean {
  const [clicked, gotClicked] = useState(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (e.target && e.target.className === className) ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (e.target.parentElement && e.target.parentElement.className === className)
      ) {
        gotClicked(true);
        clickCallBack();
        return;
      }
      gotClicked(false);
    };

    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, [className, clickCallBack]);

  return clicked;
}

/**
 *
 * @deprecated (we close SubNav on Shader click, not on outside click)
 */
export function useIsOutside({
  refElement,
  clickOutSideCallBack,
}: {
  refElement?: React.RefObject<HTMLDivElement>;
  clickOutSideCallBack?: () => void;
}): {
  ref: React.RefObject<HTMLDivElement>;
  state: boolean;
} {
  const [isOutside, setIsOutside] = useState(false);
  const refHook = useRef(null);
  const ref = refElement || refHook;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      const handleClick = (e: MouseEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (ref.current?.contains(e.target)) {
          setIsOutside(false);
          return;
        }
        if (clickOutSideCallBack) {
          clickOutSideCallBack();
        }
        setIsOutside(true);
      };

      document.addEventListener('mousedown', handleClick);
      return (): void => {
        document.removeEventListener('mousedown', handleClick);
      };
    }
  }, [ref, clickOutSideCallBack]);

  return {
    ref,
    state: isOutside,
  };
}

export const createUseFootnoteReferenceServiceTextParserHook =
  ({
    footnotes,
    referenceServiceManager,
  }: {
    footnotes: EnumerableFootnote[];
    referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3;
  }) =>
  (htmlString: string): string =>
    useFootnoteReferenceServiceTextParserHook({
      footnotes,
      htmlString,
      referenceServiceManager,
    });

const useFootnoteReferenceServiceTextParserHook = ({
  footnotes,
  referenceServiceManager,
  htmlString,
}: {
  footnotes: EnumerableFootnote[];
  referenceServiceManager?: AudiFootnoteReferenceServiceInterfaceV3;
  htmlString: string;
}): string => {
  const replacedHtmlString = htmlString?.replace(scriptRegex, ' illegal script ');
  const [state, setstate] = useState(replacedHtmlString);

  /**
   * This function adds the number for a reference and adds the contextID to the element.
   * It takes the ID of the reference, looks up the number and the contextID in the footnotes array
   * and applies the number to the element and also adds an data-attribute
   */
  const callBackToReplaceHrefInHtmlString = useCallback(
    (footnoteID: string, reference: HTMLLinkElement) => {
      const footnoteReference = footnotes.find((footnote) => footnote.id === footnoteID);
      if (footnoteReference && footnoteReference.number) {
        replaceTextWithFootnoteNumber(reference, footnoteReference.number);
        if (footnoteReference.number > -1) {
          setDataSetOnElement({
            element_: reference,
            id: footnoteReference.contextID ? footnoteReference.contextID : footnoteReference.id,
            name: 'referenceId',
            referenceId: footnoteReference.id,
          });
        }
      }
    },
    [footnotes],
  );

  useEffect(() => {
    if (referenceServiceManager) {
      const referenceIdList: unknown[] = [];
      loopOverReferences(replacedHtmlString, (footnoteID: string) => {
        referenceIdList.push(footnoteID);
      });

      if (referenceIdList.length > 0) {
        referenceServiceManager.addFootnoteReferences(referenceIdList);
      }
    }
  }, [replacedHtmlString, referenceServiceManager]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const htmlStringNew = loopOverReferences(replacedHtmlString, callBackToReplaceHrefInHtmlString);
    setstate(htmlStringNew);
  }, [replacedHtmlString, callBackToReplaceHrefInHtmlString]);

  return state;
};

export function useAuth(authService: IAuthServiceV2): {
  accessToken: string;
  isAuthenticated: boolean;
} {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    const getAuthStatus = async () => {
      const response = await authService.isAuthenticated();
      setIsAuthenticated(response);
    };
    getAuthStatus();
  }, [authService]);

  useEffect(() => {
    const getToken = async () => {
      const respAccessToken = await authService.getAccessToken();
      setAccessToken(respAccessToken);
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    accessToken,
  };
}
