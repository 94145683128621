import { keyframes } from 'styled-components';

export const iconTopOpenKeyframes = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translateY(3px);
  }

  100% {
    opacity: 0;
    transform: translateY(3px);
  }
`;

export const iconMiddleOpenKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
`;

export const iconMiddleSecondOpenKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-45deg);
  }
`;

export const iconBottomOpenKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0;
    transform: translateY(-3px);
  }

  100% {
    opacity: 0;
    transform: translateY(-3px);
  }
`;

export const iconTopCloseKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(3px);
  }

  50% {
    opacity: 0;
    transform: translateY(3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const iconMiddleCloseKeyframes = keyframes`
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const iconMiddleSecondCloseKeyframes = keyframes`
  0% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const iconBottomCloseKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-3px);
  }

  50% {
    opacity: 0;
    transform: translateY(-3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
