import React, { useContext } from 'react';

import { HeaderStyledNav, HeaderStyledNavWrapper } from './AudiHeaderNavStyles';
import AudiHeaderActiveBar from '../AudiHeaderActiveBar/AudiHeaderActiveBar';
import AudiHeaderNavItem from '../AudiHeaderNavItem/AudiHeaderNavItem';
import { AudiHeaderNavProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderDealerContext from '../AudiHeaderDealerContext/AudiHeaderDealerContext';
import { DealerContext } from '../../context';

const AudiHeaderNav: React.FC<AudiHeaderNavProps> = ({
  audiMarketContextService,
  headerNavigationItemsAmount,
  navigation,
  headerDispatch,
  headerState,
  headerStateService,
  navigationElement,
  isClosing,
  isOpen,
  useFootnoteReferenceServiceTextParserHook,
  useOneLayer,
  windowWidth,
  layerManager,
  onClickDealerContext,
  showDealerAdditionalData,
  useUserMenu,
}) => {
  const { dealerData, renderDealerContext } = useContext(DealerContext);
  return (
    <HeaderStyledNavWrapper
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isOpen={isOpen}
      isSubNavOpen={headerState.activeItem.index !== -1}
      ref={navigationElement}
      showSearch={headerState.activeItem.showSearch}
    >
      <HeaderStyledNav
        aria-label="Main"
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        isOpen={isOpen}
        isSubNavOpen={headerState.activeItem.index !== -1}
        role="navigation"
      >
        {navigation?.map((navigationItem, index) => {
          const link = navigationItem?.Link;
          const key = link ? `${link?.Text}${link?.Url}` : `link-${index}`;

          return (
            <AudiHeaderNavItem
              audiMarketContextService={audiMarketContextService}
              headerDispatch={headerDispatch}
              headerNavigationItemsAmount={headerNavigationItemsAmount}
              headerState={headerState}
              headerStateService={headerStateService}
              index={index}
              isClosing={isClosing}
              isSubNavOpen={headerState.activeItem.index !== -1}
              item={navigationItem}
              key={key}
              layerManager={layerManager}
              useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
              useOneLayer={useOneLayer}
              useUserMenu={useUserMenu}
            />
          );
        })}
      </HeaderStyledNav>
      {!!dealerData && renderDealerContext && (
        <AudiHeaderDealerContext
          isMobileView
          headerNavigationItemsAmount={headerNavigationItemsAmount}
          onClickDealerContext={onClickDealerContext}
          showDealerAdditionalData={showDealerAdditionalData}
        />
      )}
      <AudiHeaderActiveBar
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        headerState={headerState}
        windowWidth={windowWidth}
      />
    </HeaderStyledNavWrapper>
  );
};

export default AudiHeaderNav;
