export const headerStyledDealerContextMobileStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        s: 'block',
        l: 'none',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        s: 'block',
        xl: 'none',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        s: 'block',
        xxl: 'none',
      },
    },
  },
];
export const headerStyledDealerContextStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        l: 'flex',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        xl: 'flex',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        xxl: 'flex',
      },
    },
  },
];
