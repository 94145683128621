import React, { useMemo } from 'react';
import { LayoutItem, Icon, Text, Badge } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import type { IconProps } from '@audi/audi-ui-react';
import { Department } from '../../interfaces/header-components.interfaces';
import {
  getWeekDay,
  timeFormatted,
  isServiceOpen,
  getNextOpenDay,
  isBeforeOpening,
  getLocalizedTime,
  getLocalizedWeekdayName,
} from '../../utils/time-utils';
import { upperCase } from '../../utils/string-utils';
import { DealerDetailStyled, OpenHoursStyled } from './DealerDetailStyles';
import { messages } from '../../i18n/messages';

type DepartmentOpeningHoursProps = {
  department: Department;
  icon?: IconProps['name'];
};
export const DepartmentOpeningHours: React.FC<DepartmentOpeningHoursProps> = ({
  icon = 'time',
  department,
}) => {
  const { openingHours, departmentName } = department;
  const today = useMemo(() => getWeekDay(), []);
  const openingHoursToday = openingHours.find((weekday: any) => weekday.id === today);
  const openTime = timeFormatted(openingHoursToday?.timeRanges[0]?.openTime) || '';
  const closeTime = timeFormatted(openingHoursToday?.timeRanges[0]?.closeTime);
  const isOpen = isServiceOpen(openTime, closeTime);
  const opensSoon = isBeforeOpening(openTime);
  const next =
    !isOpen && !opensSoon
      ? getNextOpenDay(openingHours, openingHoursToday || openingHours[0])
      : openingHours[0];
  const openFragment = useI18n(messages.open_until);
  const closedFragment = useI18n(messages.closed_fragment);
  const todayFragment = useI18n(messages.today);
  const timeFormat = useI18n(messages.time_format);

  /* Get locale of user from browser navigator.
     Navigator is set here to prevent SSR errors
   */
  const userLocale =
    navigator?.languages && navigator?.languages?.length
      ? navigator?.languages[0]
      : navigator?.language;

  let statusText;
  if (isOpen) {
    statusText = (
      <Text>
        {openFragment} {getLocalizedTime(closeTime, timeFormat, userLocale)}
      </Text>
    );
  } else if (!opensSoon) {
    statusText = (
      <Text>
        {closedFragment} {upperCase(getLocalizedWeekdayName(next?.id, userLocale))} {` `}
        {getLocalizedTime(next?.timeRanges[0]?.openTime, timeFormat, userLocale)}
      </Text>
    );
  } else {
    statusText = (
      <Text>
        {closedFragment} {todayFragment} {getLocalizedTime(openTime, timeFormat)}
      </Text>
    );
  }

  return (
    <DealerDetailStyled>
      <LayoutItem align="center" spaceInlineEnd="s">
        <Icon name={icon} size="small" variant="primary" />
      </LayoutItem>
      <LayoutItem>
        <Text>{departmentName}</Text>
        <OpenHoursStyled>
          <Badge
            variant={isOpen ? 'positive' : 'informative'}
            spaceInlineEnd="s"
            spaceStackStart="xxs"
          />
          {statusText}
        </OpenHoursStyled>
      </LayoutItem>
    </DealerDetailStyled>
  );
};
