// eslint-disable-next-line import/no-unresolved
import { GraphQLClient, gql } from 'graphql-request';
import type { DealerData } from '../interfaces/header-components.interfaces';

const QUERY_BY_DEALER_ID = gql`
  query Dealer($dealerId: String!) {
    dealerById(dealerId: $dealerId) {
      name
      url
      additionalData {
        displayName
      }
      dealerId
      services
      address
      phone
      openingHours {
        openingHoursFormatted
        openingHoursNote
        departments {
          id
          departmentName
          departmentOpeningHoursNote
          openingHours {
            id
            open
            timeRanges {
              openTime
              closeTime
            }
          }
        }
      }
    }
  }
`;

const metaValues: any = {};
export const getMetaContent = (dom: Element, metaName: string): string => {
  if (!metaValues[metaName] || dom !== metaValues[metaName].dom) {
    const metatag = dom.querySelector(`meta[name=${metaName}]`);
    metaValues[metaName] = {
      dom,
      value: metatag?.getAttribute('content') || '',
    };
  }
  return metaValues[metaName].value;
};

export const getClientId = (): string | null => {
  return getMetaContent(document.head, 'oadd-client-id') || null;
};

export async function getDealerData(
  endpoint: string | undefined,
  dealerId: string | undefined,
): Promise<DealerData | undefined> {
  const clientId = getClientId();
  let client;

  if (__DEBUG__ && !dealerId) {
    // eslint-disable-next-line no-console
    console.warn('[nemo-header|dealer-data] no Dealer ID found');
  }

  if (endpoint !== undefined && clientId) {
    client = new GraphQLClient(endpoint, {
      headers: {
        clientId,
      },
    });
  } else if (__DEBUG__) {
    // eslint-disable-next-line no-console
    console.warn('[nemo-header|dealer-data] no graphql endpoint or clientId provided');
  }

  return dealerId && client
    ? client.request(
        QUERY_BY_DEALER_ID,
        {
          dealerId,
        },
        { accept: 'application/json' },
      )
    : undefined;
}
