export function getLocalStorageObject(key: string): unknown | null {
  const value = localStorage.getItem(key);

  if (value === null) {
    return null;
  }

  return JSON.parse(value);
}

export function getLocalStorageString(key: string): unknown | null {
  const value = localStorage.getItem(key);

  if (value === null) {
    return null;
  }

  return value.toString();
}
