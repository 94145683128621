import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

export interface ViewportBounds {
  width: number;
  height: number;
}

const isClientSide = typeof window !== 'undefined';

export const useViewport = (): ViewportBounds | undefined => {
  const [viewport, setViewport] = React.useState<ViewportBounds | undefined>(
    isClientSide
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : undefined,
  );
  const handleResize = () =>
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return viewport;
};

export interface ViewportProps {
  viewport?: ViewportBounds;
}

const GlobalStyle = createGlobalStyle<ViewportProps>`
  :root {
    --viewport-width: ${(props) => props.viewport?.width}px;
    --viewport-height: ${(props) => props.viewport?.height}px;
  }
`;

export const ViewportProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const viewport = useViewport();

  return (
    <>
      {isClientSide && <GlobalStyle viewport={viewport} />}
      {children}
    </>
  );
};
