import React, { useContext } from 'react';
import {
  LayoutItem,
  Icon,
  Text,
  Button,
  PopoverTrigger,
  Popover,
  PopoverBody,
} from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import {
  AdditionalDealerData,
  AdditionalDealerDataActionsBottom,
  AdditionalDealerDataContent,
  AdditionalDealerDataDisconnectButton,
  AdditionalDealerDataInfoButton,
} from './AudiHeaderAdditionalDealerDataStyles';
import { DealerDetail } from './DealerDetail';
import { DepartmentOpeningHours } from './DepartmentOpeningHours';
import {
  AudiHeaderDealerDataProps,
  Department,
} from '../../interfaces/header-components.interfaces';
import { upperCase } from '../../utils/string-utils';
import { messages } from '../../i18n/messages';
import { ViewportProvider } from '../../context/Viewport';
import { transformServices } from '../../utils/transformServices';
import { DealerContext } from '../../context';

const AudiHeaderAdditionalDealerData: React.FC<AudiHeaderDealerDataProps> = ({
  open,
  isMobileView,
}) => {
  const { dealerData, removeDealer } = useContext(DealerContext);
  const backToDealerText = useI18n(messages.back_to_dealer);
  const removeDealerButtonText = useI18n(messages.remove_dealer);
  const removeDealerHelpText = useI18n(messages.remove_dealer_help);

  const partnerServiceLabels = new Map<string, string>([
    ['sales', useI18n(messages.sales)],
    ['sales-r8', useI18n(messages.sales_r8)],
    ['service-r8', useI18n(messages.service_r8)],
    ['service', useI18n(messages.service)],
    ['service-noagent', useI18n(messages.service_no_agent)],
    ['etron', useI18n(messages.etron)],
    ['usedcars', useI18n(messages.usedcars)],
    ['aftersales', useI18n(messages.aftersales)],
    ['ovt', useI18n(messages.ovt)],
    ['audi-on-demand', useI18n(messages.audi_on_demand)],
  ]);
  const modifiedServices =
    dealerData !== undefined && dealerData.services.length > 0
      ? transformServices(dealerData.services, partnerServiceLabels)
      : '';

  return (
    <ViewportProvider>
      <AdditionalDealerData open={open} isMobileView={isMobileView}>
        <AdditionalDealerDataContent isMobileView={isMobileView}>
          {!isMobileView && (
            <LayoutItem spaceStackEnd="xl">
              <Text as="h2" variant="order2">
                {dealerData?.additionalData?.displayName || dealerData?.name || 'Name not found'}
              </Text>
              <Text as="p" variant="order4">
                {upperCase(modifiedServices)}
              </Text>
            </LayoutItem>
          )}
          <Button
            variant="secondary"
            href={dealerData?.url}
            spaceStackEnd={isMobileView ? 'l' : 'xl'}
            aria-label="link to dealer page"
            disabled={!open}
          >
            <Text>{backToDealerText}</Text>
          </Button>
          {dealerData?.address && <DealerDetail icon="poi-icon" detail={dealerData?.address} />}
          {dealerData?.phone && <DealerDetail icon="voice-call-1" detail={dealerData?.phone} />}
          {dealerData?.openingHours &&
            dealerData?.openingHours.departments.map((department: Department) => (
              <DepartmentOpeningHours key={department.departmentName} department={department} />
            ))}
          <AdditionalDealerDataActionsBottom isMobileView={isMobileView}>
            <AdditionalDealerDataDisconnectButton
              onClick={removeDealer}
              aria-label="disconnect from current dealer"
              tabIndex={open ? 0 : -1}
            >
              <LayoutItem align="center" spaceStackStart="xxs" spaceInlineEnd="xs">
                <Icon name="cancel" size="small" variant="primary" />
              </LayoutItem>
              <LayoutItem align="center">
                <Text>{removeDealerButtonText}</Text>
              </LayoutItem>
            </AdditionalDealerDataDisconnectButton>
            <AdditionalDealerDataInfoButton>
              <PopoverTrigger placement="top">
                {(triggerProps) => (
                  <>
                    <Button
                      aria-label="triggers popover for remove dealer"
                      variant="icon-tertiary"
                      icon="system-info"
                      disabled={!open}
                      {...triggerProps}
                    />
                    <Popover aria-label="Popover content for remove dealer">
                      <PopoverBody>
                        <Text>{removeDealerHelpText}</Text>
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </PopoverTrigger>
            </AdditionalDealerDataInfoButton>
          </AdditionalDealerDataActionsBottom>
        </AdditionalDealerDataContent>
      </AdditionalDealerData>
    </ViewportProvider>
  );
};

export default AudiHeaderAdditionalDealerData;
