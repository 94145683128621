interface validLinkType {
  Text: string;
  Url: string;
  Target?: string;
  Type?: string;
  IsAudiSport?: boolean;
}

export const isValidLink = (Link: validLinkType): boolean => {
  return (
    Object.keys(Link).length > 1 &&
    Link.Text !== undefined &&
    Link.Text !== '' &&
    Link.Url !== undefined &&
    Link.Url !== ''
  );
};
