import fetch from 'node-fetch';
import HeaderResponse from '../interfaces/header-response.interfaces';

const initialHeaderConfig: HeaderResponse = {
  Logo: {
    Text: '',
    Url: '',
  },
  MainNavigation: [],
  MenuLabel: '',
  OneShopEnabled: false,
  Search: {
    Action: '',
    DealerSearchLink: '',
    Label: '',
    Legend: '',
    NewCarsLink: '',
    OneHeaderSearchClientId: '',
    Placeholder: '',
    QueryParam: '',
    ShowDealerSearchLink: false,
    ShowNewCarsLink: false,
    ShowUsedCarsLink: false,
    UsedCarsLink: '',
  },
  Suppliers: [],
};

const fetchHeaderConfig: (url: string | undefined) => Promise<HeaderResponse> = async (url) => {
  if (typeof url !== 'undefined') {
    try {
      const response = await fetch(url);
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }
  return initialHeaderConfig;
};

export { initialHeaderConfig, fetchHeaderConfig };
