import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { responsiveStyles } from '@audi/audi-ui-react';
import { AudiCopy1WithHoverStyle } from '../AudiHeader/AudiHeaderStyleUtils';
import { HeaderStyledNavItemProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services';

type NavItemsStylesArray = Array<{ maxNavItems: number; style: unknown }>;
const headerStyledNavItemStyles = (): NavItemsStylesArray => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      margin: {
        l: 'var(--one-header-space-l) var(--one-header-space-l) 0 0',
      },
      padding: { l: '0' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      margin: {
        xl: 'var(--one-header-space-l) var(--one-header-space-l) 0 0',
      },
      padding: { xl: '0' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      margin: {
        xxl: 'var(--one-header-space-l) var(--one-header-space-l) 0 0',
      },
      padding: { xxl: '0' },
    },
  },
  {
    maxNavItems: -1, // > 8 navItems
    style: {
      margin: {
        xs: 'var(--one-header-space-l) 0',
      },
    },
  },
];

const headerStyledNavItemTextStyles = (): NavItemsStylesArray => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      'margin-right': { l: '0' },
      'white-space': { l: 'nowrap' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      'margin-right': { xl: '0' },
      'white-space': { xl: 'nowrap' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      'margin-right': { xxl: '0' },
      'white-space': { xxl: 'nowrap' },
    },
  },
];

const headerStyledNavItemIconStyles = (): NavItemsStylesArray => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: { l: 'none' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: { xl: 'none' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: { xxl: 'none' },
    },
  },
];

export const HeaderStyledNavItem = styled.div<HeaderStyledNavItemProps>`
  display: block;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledNavItemStyles()),
      theme.breakpoints,
    )}
  padding: calc(var(--one-header-space-l) / 2) 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const HeaderStyledNavItemLink = styled.a<{
  headerNavigationItemsAmount: number;
}>`
  color: var(${({ theme }): string => theme.colors.base.grey[60]});
  display: flex;
  flex-flow: row nowrap;
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--one-header-color-black);
  }

  & > span {
    flex-grow: 1;
    ${({ headerNavigationItemsAmount, theme }): string =>
      responsiveStyles(
        createBreakpointStyles(headerNavigationItemsAmount, headerStyledNavItemTextStyles()),
        theme.breakpoints,
      )}
  }

  & > svg {
    height: 24px;
    ${({ headerNavigationItemsAmount, theme }): string =>
      responsiveStyles(
        createBreakpointStyles(headerNavigationItemsAmount, headerStyledNavItemIconStyles()),
        theme.breakpoints,
      )}
    width: 24px;
  }

  &:hover {
    color: var(--one-header-color-black);
  }

  &:active {
    color: var(--one-header-color-black);
  }
`;
