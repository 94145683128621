export const KEY_DEALER_DATA = 'fa-header-dealer-data';
export const DEALER_CONTEXT_UNSUBSCRIBE = 'unsubscribe';
export const customEaseOut = 'cubic-bezier(0.21, 0.55, 0.43, 1)';
export const MAX_LENGTH_DEALER_NAME = 30;
export const LAMBDA_CUSTOMER_PROFILE_URL_TEST =
  'https://fa-nemo-header.api.dev.arcade.apps.one.audi/api';
export const LAMBDA_CUSTOMER_PROFILE_URL_LIVE =
  'https://fa-nemo-header.api.prod.arcade.apps.one.audi/api';
export const MY_AUDI_ID = 'a2c-session-token';
export const AUDI_AUTH_STORE = 'audi:authStore';
