import classNames from 'classnames';
import { Link } from '../interfaces/header-response.interfaces';

const footnotesAnchorTextSelector = '.audi-footnote-anchor__text';
const referenceSelector = '.audi-j-footnote-reference';

export const getElementsWidth: (elements: HTMLElement[]) => number = (elements) => {
  return elements.reduce((accumulatedWidth, element) => element.offsetWidth + accumulatedWidth, 0);
};

export const getNotNullElements: (elements: HTMLDivElement[]) => HTMLDivElement[] = (elements) => {
  return elements.filter((measureElement) => measureElement !== null);
};

export const getClassNamesForLinks: (link: Link, useOneLayer?: boolean) => string = (
  link,
  useOneLayer,
) => {
  return classNames({
    'j-action-usabilla': link?.Type === 'feedback',
    'nm-j-poa': link?.Type === 'poa',
    'nm-layerLink': link?.Target === '_layer' && !useOneLayer,
    'nm-pageOpen': link?.Target === '_inpage',
  });
};

export const getLinkTarget: (link: Link) => string = (link) => {
  let target = link?.Target || '_self';

  /* nemo layer link specific settings */
  if (!link?.Target || link?.Target === '_inpage' || link?.Target === '_layer') {
    target = '_self';
  }

  return target;
};

export function extractFootnoteIdFromString(rawKey_: string): string {
  let key = rawKey_;
  const index = key.indexOf('#');

  if (index !== -1) {
    key = key.substr(index + 1);
  }

  return key;
}

export function replaceTextWithFootnoteNumber(element_: HTMLElement, number_: number): void {
  if (!element_.dataset.fixedText && number_ > 0) {
    const textContainer = element_.querySelector(footnotesAnchorTextSelector);
    if (textContainer) {
      textContainer.innerHTML = `${number_}`;
    }
  }
}

export function setDataSetOnElement({
  element_,
  name,
  id,
  referenceId,
}: {
  element_: HTMLLinkElement;
  name: string;
  id: string;
  referenceId: string;
}): void {
  // eslint-disable-next-line no-param-reassign
  element_.href = `#${id}`;
  // eslint-disable-next-line no-param-reassign
  element_.dataset[name] = referenceId;
}

export const loopOverReferences = (
  htmlString: string,
  callBackPerId: (id: string, ref?: HTMLLinkElement) => void,
): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const allReferencesFromString = div.querySelectorAll(referenceSelector);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  allReferencesFromString.forEach((reference: HTMLLinkElement) => {
    const footnoteIDFromHref = extractFootnoteIdFromString(reference.href);
    const footnoteIDFromDataAttribute = reference.dataset.referenceId
      ? extractFootnoteIdFromString(reference.dataset.referenceId)
      : undefined;
    const footnoteID = footnoteIDFromDataAttribute || footnoteIDFromHref;
    callBackPerId(footnoteID, reference);
  });

  return div.innerHTML;
};

export const createBreakpointStyles = (
  maxNavItems: number,
  styles: Array<{ maxNavItems: number; style: unknown }>,
  // any because "BreakpointStyles<T>" from audi-ui-react is expected but type is not public
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,consistent-return
): any => {
  if (maxNavItems === 0) {
    return styles[0].style;
  }
  if (maxNavItems <= 4) {
    return styles[1].style;
  }
  if (maxNavItems <= 5) {
    return styles[2].style;
  }
  if (maxNavItems <= 8) {
    return styles[3].style;
  }
  if (styles[4]) {
    return styles[4].style;
  }
};
