/*
  The core-renderer file is explicitly taken from the below repo.
  https://github.com/oneaudi/myaudi/tree/develop/shared/myaudi-components/patterns/rich-text
  The import from myaudi could not be possible due to authorisation issues.
  During the serving of the fa there appears a problem with the authentication to npm. 
  Hence the files core-renderer and index.tsx have been explicitly taken from the repo and added in the oneHeader Fa.
  We will replace this preferably with a component from Audi Ui React in the near future.
*/
import * as React from 'react';
import { Text, List, ListItem, TypographyVariantLabels } from '@audi/audi-ui-react';

import { Attributes } from './index';

export const coreRenderer = (
  node: Node,
  attributes: Attributes,
  nodeContent: JSX.Element[],
): JSX.Element | string | null => {
  const key = node.parentNode ? Array.prototype.indexOf.call(node.parentNode.childNodes, node) : 0;

  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  }

  const element = node as Element;
  const tagName = element.tagName.toLowerCase();

  switch (tagName) {
    case 'h1':
    case 'h2':
    case 'h3':
      <Text
        key={key}
        variant={tagName.replace(/^h(\d)/, 'order$1') as TypographyVariantLabels}
        spaceStackEnd="l"
      >
        {nodeContent}
      </Text>;
      break;
    case 'a':
      return (
        <Text key={key} as="span" variant="copy1" spaceStackEnd="l">
          <a {...attributes}>{nodeContent}</a>
        </Text>
      );
    case 'ul':
    case 'ol':
      return (
        <List key={key} textVariant="copy2" spaceStackEnd="l">
          {nodeContent}
        </List>
      );
    case 'li':
      return (
        <ListItem key={key} spaceStackEnd="m">
          {nodeContent}
        </ListItem>
      );
    default:
      return '';
  }

  return null;
};
