// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useCallback } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Theme } from '@audi/audi-ui-react';
import { getClassNamesForLinks, getLinkTarget } from '../../services';
import { AudiCopy1WithHoverStyle } from '../AudiHeader/AudiHeaderStyleUtils';
import { AudiHeaderSubNavListItemProps } from '../../interfaces/header-components.interfaces';
import { scriptRegex } from '../../utils/string-utils';

const HeaderStyledSubNavListItem = styled.li<{
  isAudiSport: boolean;
  theme: Theme;
}>`
  margin-bottom: var(--one-header-space-l);

  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ isAudiSport }): string =>
    isAudiSport &&
    `
    padding-left: 42px;
    position: relative;

    &::before {
      background-color: var(--one-header-color-red-rhombus);
      content: "";
      display: inline-block;
      height: 24px;
      left: 6px;
      position: absolute;
      transform: skew(-28deg);
      vertical-align: top;
      width: 24px;
    }
  `}
`;

const HeaderStyledSubNavListItemLink = styled.a`
  color: var(${({ theme }): string => theme.colors.base.grey[60]});
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--one-header-color-black);
  }
`;

const AudiHeaderSubNavListItem: React.FC<AudiHeaderSubNavListItemProps> = ({
  listItem,
  useFootnoteReferenceServiceTextParserHook,
  onItemClick,
  isSubNavOpen,
  useOneLayer,
  index,
}) => {
  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    listItem.Text.replace(scriptRegex, ' illegal script '),
  );

  const onClick = useCallback(
    (event) => {
      return onItemClick(event, listItem);
    },
    [listItem, onItemClick],
  );

  return (
    <HeaderStyledSubNavListItem isAudiSport={listItem.IsAudiSport}>
      <HeaderStyledSubNavListItemLink
        tabIndex={isSubNavOpen ? 0 : -1}
        aria-label={listItem.Text}
        className={getClassNamesForLinks(listItem, useOneLayer)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: dangerousHTML,
        }}
        href={listItem.Url}
        onClick={onClick}
        target={getLinkTarget(listItem)}
        data-index={index}
      />
    </HeaderStyledSubNavListItem>
  );
};

export default AudiHeaderSubNavListItem;
