// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import HeaderResponse, { MenuItem } from '../interfaces/header-response.interfaces';
import { isEmpty } from './is-empty';
import { isValidLink } from './is-valid-link';

export const cleanNavigationEntries = (headerResponse?: HeaderResponse): HeaderResponse => {
  const _mainNavigation: MenuItem[] = [];
  headerResponse.MainNavigation.map((navItemMain, indexX) => {
    if (!isEmpty(navItemMain.Link) && isValidLink(navItemMain.Link)) {
      if (!isEmpty(navItemMain.SubNavigation)) {
        const _subNavigation = [];
        navItemMain.SubNavigation.map((navItemSub, indexY) => {
          if (!isEmpty(navItemSub) && isValidLink(navItemSub)) {
            _subNavigation.push(navItemSub);
          } else {
            // eslint-disable-next-line no-console
            console.error(
              `[feature-app-oneheader] sub navigation item in "${
                navItemMain.Link.Text
              }" on position ${indexY + 1} seems corrupted and can't be shown.`,
            );
          }
        });
        navItemMain.SubNavigation = _subNavigation;
      }
      _mainNavigation.push(navItemMain);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `[feature-app-oneheader] main navigation item on position ${
          indexX + 1
        } seems corrupted and can't be shown.`,
      );
    }
  });
  headerResponse.MainNavigation = _mainNavigation;
  return headerResponse;
};
