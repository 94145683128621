import { FlattenSimpleInterpolation, css } from 'styled-components';
import { Theme } from '@audi/audi-ui-react';

export const AudiCopy1WithHoverStyle = (theme: Theme): FlattenSimpleInterpolation => css`
  font-family: var(${theme.responsive.typography.copy1.fontFamily});
  font-size: var(${theme.responsive.typography.copy1.fontSize});
  font-stretch: var(${theme.responsive.typography.copy1.fontStretch});
  line-height: var(${theme.responsive.typography.copy1.lineHeight});

  &:hover {
    color: var(${theme.colors.interaction.secondary.hover});
  }
`;

export const timeS = '120ms';
export const timeM = '180ms';
export const timeL = '240ms';
export const timeXL = '420ms';
export const timeXXL = '660ms';
export const timeHeaderTransitionDelay = '300ms'; // time m + s

export const generateMobileMenuMaxHeightCss = (): string => {
  if (typeof window !== 'undefined' && window.innerHeight) {
    return `calc(${window.innerHeight}px - var(--one-header-height))`;
  }
  return 'calc(100vh - var(--one-header-height))';
};
